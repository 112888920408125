<template>
  <div>
    <b-card>
      <own-table ref="refundTable" name-table="refund-datas" :table-columns="columns" :disable-create-btn="true"
        :use-custom-filter="true" :custom-query-filter="queryFilter" sort-by="created_at" sort-direction="desc"
        @searchFieldOnChange="searchOnChange">
        <template #cell(transaction_date)="data">
          {{
            data.data.item.transaction
            ? formatDate(data.data.item.transaction.created_at)
            : '-'
          }}
        </template>
        <template #cell(has_paid)="data">
          <b-badge :variant="paidStatusVariant(data.data.item.has_paid)">
            <span>{{
              data.data.item.has_paid ? 'Sudah Bayar' : 'Belum Bayar'
            }}</span>
          </b-badge>
        </template>
        <template #cell(refrence)="data">
          <router-link :to="{
            name: data.data.item ? data.data.item.ref_type ===
              'App\\Models\\Order'
              ? 'order-detail' : 'return_sales_detail' : '-',
            params: { id: data.data.item.refrence.id },
          }">
            <span>{{ data.data.item.ref_type ===
              'App\\Models\\Order'
              ? data.data.item.refrence.transaction.no_invoice : data.data.item.refrence.number }}</span>
          </router-link>
        </template>
        <template #cell(actions)="data">
          <feather-icon :id="`branch-row-${data.data.item.number}-preview-icon`" icon="EyeIcon" size="16" @click="() =>
            $router.push({
              name: 'refunds_detail',
              params: { id: data.data.item.id },
            })
            " />
          <feather-icon
            v-if="data.data.item.bank_name && data.data.item.bank_account_name && data.data.item.bank_account_number"
            :id="`branch-row-${data.data.item.number}-pay-icon`" icon="DollarSignIcon" size="16" class="ml-1"
            @click="showPayout(data.data.item.id)" />
        </template>
      </own-table>
    </b-card>
    <b-modal v-model="payoutModal" centered title="Refund" scrollable ok-title="Iya" cancel-title="Tidak"
      no-close-on-backdrop @ok="doPaid">
      <h5 class="mb-2">Apakah anda yakin sudah melakukan Pembayaran?</h5>
      <template #modal-footer="{ cancel, ok }">
        <b-button :disabled="confirmLoading" @click="cancel"> Tidak </b-button>
        <b-button :disabled="confirmLoading" variant="primary" @click="ok"><b-spinner v-if="confirmLoading" small
            class="mr-1" /><span>Iya</span></b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { BCard, BBadge, BTooltip, BAvatar, BButton, BSpinner } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import vSelect from 'vue-select'
import { formatCurrency, formatDate, title } from '@core/utils/filter'
import { statusOrder, statusOrderVariant } from '@core/utils/constant'
import service from '@/components/Table/service'
import useRefund from './useRefund'
import refund from '@/router/routes/refund'

// import FilterFields from '../components/filter/Filter.vue'
// import calonAgenStoreModule from './calonAgenStoreModule'

export default {
  components: {
    BCard,
    // FilterFields,
    OwnTable,
    BBadge,
    BTooltip,
    BAvatar,
    BButton,
    BSpinner
  },
  setup(props, { root }) {
    const { paidStatusVariant } = useRefund()
    const columns = ref([
      {
        label: 'Doc. Number',
        key: 'doc_number',
      },
      {
        label: 'Ref. Refund',
        key: 'ref_type',
        formatter: val => (val === 'App\\Models\\Order' ? 'Order' : 'Return Order'),
      },
      {
        label: 'No. Refrence',
        key: 'refrence',
        formatter: val => (val === 'App\\Models\\Order' ? val.transaction.no_invoice : val.number),
      },
      {
        label: 'Total',
        key: 'amount_refund',
        formatter: val => formatCurrency(val),
      },
      {
        label: 'Nama Bank',
        key: 'bank_name',
        formatter: val => val || '-',
      },
      {
        label: 'No. Rek',
        key: 'bank_account_number',
        formatter: val => val || '-',
      },
      {
        label: 'Nama',
        key: 'bank_account_name',
        formatter: val => val || '-',
      },
      {
        label: 'Status',
        key: 'has_paid',
        sortable: true,
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const queryFilter = ref('')
    const search = ref('')
    const searchOnChange = val => {
      console.log(val)
      search.value = val
    }

    const refundTable = ref(null)

    const refundIdTemp = ref(null)
    const payoutModal = ref(false)
    const confirmLoading = ref(false)
    const doPaid = evt => {
      confirmLoading.value = true
      evt.preventDefault()
      service
        .store({ url: 'refund/pay', data: { id: refundIdTemp.value } })
        .then(res => {
          payoutModal.value = false
          refundTable.value.refetchData()
        })
        .finally(() => {
          confirmLoading.value = false
        })
    }
    const showPayout = (refundId) => {
      payoutModal.value = true
      refundIdTemp.value = refundId
    }

    return {
      columns,
      queryFilter,
      searchOnChange,
      formatDate,
      paidStatusVariant,
      refundTable,
      payoutModal,
      showPayout,
      doPaid,
      confirmLoading
    }
  },
}
</script>
