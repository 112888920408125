export default function useRefund() {
  const paidStatusVariant = isPaid => {
    if (isPaid) {
      return 'success'
    }
    return 'danger'
  }
  return {
    paidStatusVariant,
  }
}
